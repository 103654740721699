export function initializeSliderCarousel() {
  let carouselSliderElements = document.querySelectorAll(
    ".carousel.carousel-slider"
  );
  if (carouselSliderElements.length > 0) {
    const sliderCarousels = M.Carousel.init(carouselSliderElements, {
      fullWidth: true,
      indicators: true,
    });
    window.setInterval(function() {
      sliderCarousels[0].next();
    }, 7000);
    document.querySelectorAll(".moveNextCarousel").forEach(el => {
      el.addEventListener("click", function(e) {
        e.preventDefault();
        e.stopPropagation();
        sliderCarousels[0].next();
      });
    });
    // move prev carousel
    document.querySelectorAll(".movePrevCarousel").forEach(el => {
      el.addEventListener("click", function(e) {
        e.preventDefault();
        e.stopPropagation();
        sliderCarousels[0].prev();
      });
    });
  }
}