export function setupCountryStateCity(attribute_prefix) {
  document.querySelector(`${attribute_prefix}country_id`)?.addEventListener("change", function () {
    const country_id = this.value;
    fetch(`/api/v2/states?country_id=${country_id}`).then(response => response.json().then(data => {
      const selectState = document.querySelector(`${attribute_prefix}state_id`);
      selectState.replaceChildren();
      const selectCity = document.querySelector(`${attribute_prefix}city`);
      selectCity.replaceChildren();

      let opt = document.createElement("option");
      opt.value = "";
      opt.innerHTML = "";
      selectState.append(opt);
      // selectState.append('<option selected></option>');

      //data['states'].forEach(d => selectState.append(`<option value='${d['id']}'>${d['name']}</option>`));
      data["states"].forEach(function (d) {
        opt = document.createElement("option");
        opt.value = d["id"];
        opt.innerHTML = d["name"];
        selectState.append(opt);
      });
      selectState.selectedIndex = 0;
      if (typeof M !== "undefined") { 
        M.FormSelect([selectState], {});
      }
    }));
  });

  document.querySelector(`${attribute_prefix}state_id`)?.addEventListener("change", function () {
    const state_id = document.querySelector(`${attribute_prefix}state_id`).value;
    fetch(`/api/v2/cities?state_id=${state_id}`).then(response => response.json().then(data => {
      const selectCity = document.querySelector(`${attribute_prefix}city`);
      selectCity.replaceChildren();

      let opt = document.createElement("option");
      opt.value = "";
      opt.innerHTML = "";
      selectCity.append(opt);
      // selectCity.append('<option selected></option>');

      //data['cities'].forEach(d => selectCity.append(`<option value='${d}'>${d}</option>`));
      data["cities"].forEach((d) => {
        opt = document.createElement("option");
        opt.value = d;
        opt.innerHTML = d;
        selectCity.append(opt);
      });
      selectCity.selectedIndex = 0;
      if (typeof M !== "undefined") {
        M.FormSelect([selectCity], {});
      }
    }));
  });
}
